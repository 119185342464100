import styled, { css } from "styled-components";
import React from "react";

const Req = () => (
  <span style={{ color: "red", display: "inline-block" }}>*</span>
);
const FormElement = styled.div`
  margin-bottom: 1.5rem;
  ${props =>
    props.single &&
    css`
      margin: 0 auto 1.5rem auto;
      width: 70%;
      max-width: 1050px;
    `}

  ${props =>
    props.dropdown &&
    css`
       {
        select {
          border-radius: 5px;
          font-size: 16px;
          padding: 11px 10px;
          border: 1px solid #ccc;
        }
      }
    `}

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  small {
    display: block;
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    position: relative;
    color: #721c24;
    z-index: 500;
    padding: 3px 10px;
    margin-top: 0.5rem;
    border-radius: 5px;
  }

  small:empty {
    display: none;
  }

  select {
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #ccc;
  }

  textarea {
    padding: 5px 10px;
  }
`;

const SubmitButton = styled.input`
  display: inline-block;
  padding: 10px 50px;
  margin-left: 15%;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  border-radius: 25px;
  border-width: 5px;
  border-color: transparent;
  background: #286ff7;
  color: #fff;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export { SubmitButton, FormElement, Req };
