import React from "react";

import { Layout, SEO } from "../../components/structure";
import { Content, Hero } from "../../components/blocks";
import { graphql, useStaticQuery } from "gatsby";
import ContactUsForm from "../../components/structure/ContactUsForm";
import NewContactUsForm from "../../components/structure/NewContactUsForm";

const ScheduleAppointmentPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-839992792.jpg" }) {
        ...HeroImage
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Get in Touch"
        description={"Schedule a call with a member of our sales team."}
      />
      <Hero title={"Schedule a call"} image={data["heroImg"]} />
      <Content>
        {location.search.indexOf("submitted") === -1 ? (
          <div
            style={{
              width: "70%",
              maxWidth: "750px",
              margin: "50px auto 0 auto",
              textAlign: "justify",
            }}
          >
            {" "}
            <p>
              Complete the form below to schedule an introductory call with a
              member of our sales team. We’ll discuss your challenges and work
              with you to develop a customized plan for engaging your program
              members, then put it into action.
            </p>
            <p style={{ fontWeight: "800" }}>
              This form is for business inquiries only. If you are looking for
              customer support, please contact your loyalty program, or the
              Points Customer Support team at{" "}
              <a href="https://questions.points.com/hc/en-us">
                {" "}
                questions.points.com
              </a>
              .{" "}
            </p>
          </div>
        ) : (
          <p></p>
        )}

        {location.search.indexOf("submitted") === -1 ? (
          <NewContactUsForm returnURL={location.href} />
        ) : (
          <p>
            Thank you! Your request has been submitted, and a member of our
            sales team will be in touch.
          </p>
        )}
      </Content>
    </Layout>
  );
};

export default ScheduleAppointmentPage;
