import React from "react";
import { Column, Grid } from "../blocks";
import { FormElement, Req, SubmitButton } from "../blocks/forms";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "react-final-form";
import english from "../../../content/translations/en-CA/contact.json";
import french from "../../../content/translations/fr-CA/contact.json";

const RecaptchaKey = "6LdRTiAdAAAAAJKnd7u1oETrHAP417MWwQZsBjkJ";
// Points reCAPTCHA site Key
// const RecaptchaKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
//Local reCAPTCHA testing site Key

class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        email: "",
        recaptcha: "",
      },
      valid: {
        first_name: false,
        last_name: false,
        company: false,
        phone: false,
        email: false,
        recaptcha: false,
      },
      formValid: false,
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      email: "",
      recaptcha: false,
      lang: english,
    };
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let first_nameValid = this.state.valid.first_name;
    let last_nameValid = this.state.valid.last_name;
    let companyValid = this.state.valid.company;
    let phoneValid = this.state.valid.phone;
    let emailValid = this.state.valid.email;
    let captchaValid = this.state.valid.recaptcha;

    switch (fieldName) {
      case "first_name":
        first_nameValid = value !== "";
        fieldValidationErrors.first_name = first_nameValid
          ? ""
          : this.state.lang.contact_form.validation.fname;
        break;
      case "last_name":
        last_nameValid = value !== "";
        fieldValidationErrors.last_name = last_nameValid
          ? ""
          : this.state.lang.contact_form.validation.lname;
        break;
      case "company":
        companyValid = value !== "";
        fieldValidationErrors.company = companyValid
          ? ""
          : this.state.lang.contact_form.validation.company;
        break;
      case "phone":
        phoneValid = value !== "";
        fieldValidationErrors.phone = phoneValid
          ? ""
          : this.state.lang.contact_form.validation.phone;
        break;
      case "email":
        emailValid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailValid && value.indexOf("@example.com") === -1;
        fieldValidationErrors.email = emailValid
          ? ""
          : this.state.lang.contact_form.validation.email;
        break;
      case "recaptcha":
        captchaValid = value !== null;
        captchaValid = captchaValid && value !== undefined;
        fieldValidationErrors.recaptcha = captchaValid
          ? ""
          : this.state.lang.contact_form.validation.robot;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        valid: {
          first_name: first_nameValid,
          last_name: last_nameValid,
          company: companyValid,
          phone: phoneValid,
          email: emailValid,
          recaptcha: captchaValid,
        },
      },
      this.validateForm
    );
  }

  validateForm() {
    const isValid =
      this.state.first_name &&
      this.state.valid.last_name &&
      this.state.valid.company &&
      this.state.valid.phone &&
      this.state.valid.email &&
      this.state.valid.recaptcha;

    this.setState({ formValid: isValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  getFormError(field) {
    if (this.state.formErrors[field]) {
      return <small>{this.state.formErrors[field]}</small>;
    }
  }

  validateRecaptcha(value) {
    this.setState({ recaptcha: value }, () => {
      this.validateField("recaptcha", value);
    });
  }

  render() {
    return (
      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
        style={{ maxWidth: "1280px", margin: "20px auto", fontWeight: "700" }}
      >
        <fieldset style={{ border: "none" }}>
          <legend></legend>
          <input
            type="hidden"
            id="WebToLead_Source__c"
            name="WebToLead_Source__c"
            value=""
            className="text"
          />
          <input
            type="hidden"
            id="Contact_Us_Type__c"
            name="Contact_Us_Type__c"
            value=""
            className="text"
          />
          <input type="hidden" name="oid" value="00D80000000LKWA" />
          <input
            type="hidden"
            className="return_url"
            name="retURL"
            value={`${this.props.returnURL}?submitted`}
          />
          {/* <!-- Partner Lead Record --> */}
          <input
            type="hidden"
            id="recordType"
            name="recordType"
            value="012C0000000Btge"
          />
          <Grid columns={2} form>
            <Column>
              <FormElement>
                <label htmlFor="first_name">
                  {this.state.lang.contact_form.fields.firstName}
                  <Req />
                </label>
                <input
                  id="first_name"
                  maxLength="40"
                  name="first_name"
                  placeholder="First name"
                  onChange={event => this.handleUserInput(event)}
                  type="text"
                />
                {this.getFormError("first_name")}
              </FormElement>
            </Column>
            <Column>
              <FormElement>
                <label htmlFor="last_name">
                  {this.state.lang.contact_form.fields.lastName}
                  <Req />
                </label>
                <input
                  id="last_name"
                  className="last_name"
                  maxLength="80"
                  name="last_name"
                  onChange={event => this.handleUserInput(event)}
                  placeholder="Last name"
                  type="text"
                />
                {this.getFormError("last_name")}
              </FormElement>
            </Column>
          </Grid>
          <Grid columns={2} form>
            <Column>
              <FormElement>
                <label htmlFor={"email"}>
                  {this.state.lang.contact_form.fields.email}
                  <Req />
                </label>
                <input
                  id="email"
                  className="email"
                  maxLength="80"
                  name="email"
                  onChange={event => this.handleUserInput(event)}
                  placeholder="Email"
                  size="20"
                  type="email"
                />
                {this.getFormError("email")}
              </FormElement>
            </Column>
            <Column>
              <FormElement>
                <label htmlFor="company">
                  {this.state.lang.contact_form.fields.company}
                  <Req />
                </label>
                <input
                  id="company"
                  className="company"
                  maxLength="40"
                  name="company"
                  onChange={event => this.handleUserInput(event)}
                  placeholder="Company"
                  size="20"
                  type="text"
                />
                {this.getFormError("company")}
              </FormElement>
            </Column>
          </Grid>

          <FormElement single>
            <label htmlFor={"phone"}>
              {this.state.lang.contact_form.fields.phone}
              <Req />
            </label>
            <input
              id="phone"
              className="phone"
              maxLength="40"
              name="phone"
              onChange={event => this.handleUserInput(event)}
              placeholder="Phone"
              size="20"
              type="tel"
            />
            {this.getFormError("phone")}
          </FormElement>

          <FormElement single>
            <label htmlFor={"00NC0000005VhZf"}>
              {this.state.lang.contact_form.fields.desc}
            </label>
            <textarea
              style={{ height: 150 }}
              id="00NC0000005VhZf"
              name="00NC0000005VhZf"
              placeholder="Tell us more about your loyalty program revenue goals."
              onChange={event => this.handleUserInput(event)}
            ></textarea>
          </FormElement>
          <FormElement single>
            <ReCAPTCHA
              id="contact-recaptcha"
              sitekey={RecaptchaKey}
              size="normal"
              onChange={value => this.validateRecaptcha(value)}
              onExpired={value => this.validateRecaptcha(value)}
            />
            {this.getFormError("recaptcha")}
          </FormElement>

          <input
            id="middle_name"
            name="middle_name"
            type="hidden"
            tabIndex="-1"
            value="&nbsp;"
          />

          <SubmitButton
            type={"submit"}
            name="submit"
            disabled={!this.state.formValid}
            value={"SUBMIT"}
            label={this.state.lang.contact_form.fields.buttonLabel}
          />
        </fieldset>
      </form>
    );
  }
}

export default ContactUsForm;
